import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../../components/Page/ContentfulPage'
import Breadcrumb from '../../components/Breadcrumb'
import withContentfulBlocks from '../../components/withContentfulBlocks'

class ValuesIndex extends React.Component {
  render () {
    return (
      <ContentfulPage
        contentAbove={
          <Breadcrumb items={[
            {
              title: 'About',
              path: '/about'
            },
            {
              title: 'Values'
            }
          ]}
          />
        }
        blocks={this.props.blocks}
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')} / Values`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}/about/values`}
        pageDescription='Our mission – A new generation of access to care.'
      />
    )
  }
}

export default withContentfulBlocks(ValuesIndex)

export const pageQuery = graphql`
  query FSValuesQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "6bz2EGWwysqaOcQeCBoNb5" }) {
      ...PageBlocksFragment
    }
  }
`
